// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

$(document).ready(function ()
{

	/*
	* FADE IN ELEMENTS ON SCROLL
	*/

   /*window.sr = ScrollReveal();
	sr.reveal('.accomodation', { duration: 2000 }, 50);
	sr.reveal('.galery-item', { duration: 2000 }, 50);
	sr.reveal('.btn-book', { duration: 2000 });
	sr.reveal('.about', { duration: 2000 });
	sr.reveal('footer', { duration: 1000 });
	sr.reveal('.acco-content', { duration: 2000 });
	sr.reveal('.omgevingcarousel', { duration: 2000 });
	*/


	$("#scrolldown").click(function() {
	    $('html, body').animate({
	        scrollTop: $("#scrolldown").offset().top - $('.header').height()
	    }, 500);
	});





	/*
	* ACCOMODATION CONTENT BOX
	*/
	$('.acco-btn').click(function() {
		$('.acco-btn').removeClass('active');
		$(this).addClass('active');
		$('#content .acco-data').hide();
	    var target = '#' + $(this).data('target');
	    $(target).show();
	})


	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers : {
			media : {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click( startVideo );
	$(".rc-video__overlay").not('.lightbox-media').click( startVideo );

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0','autoplay=1') );
		} else {
			iframe.attr('src', src + '?autoplay=1');
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Image slider
	$('.rc-image-slider__slick').slick({
		adaptiveHeight: true,
		dots: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});
});

$(window).on('load', function ()
{
	/* na ingeladen images ... */
});